<template>
  <el-row class="movie-list">
    <el-col :md="20">
      <el-row>
        <h3>确认收货地址</h3>
        <el-divider />
        <div v-for="(item, index) in addresses">
          <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
            <el-radio v-model="radio" :label="item.deliveryId">{{ item.address }}</el-radio>
          </el-row>
        </div>
      </el-row>
      <el-row>
        <h3>确认订单信息</h3>
        <el-divider />
        <el-table
          :data="dataList"
          :show-header="true"
          border
          style="width: 100%"
        >
          <el-table-column
            fixed="left"
            label="商品信息"
          >
            <template slot-scope="scope">
              <el-row>
                <el-col :md="4">
                  <el-image :src="scope.row.picUrl" min-width="30" height="20" />
                </el-col>
                <el-col :md="20">
                  <router-link target="_blank" style="text-decoration-line: none" :to="`/mall/item?id=${scope.row.itemId}`">
                    <span>{{ scope.row.title }}</span>
                  </router-link>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column
            prop="sku"
            label="sku"
          />
          <el-table-column
            label="单价"
          >
            <template slot-scope="scope">
              <span style="color: red">￥{{ scope.row.price }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="num"
            label="数量"
          >
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.num" :min="1" :max="20" style="margin-left: 5px" />
            </template>
          </el-table-column>
          <el-table-column
            prop="num"
            label="小计"
          />
        </el-table>
        <el-divider />
        <el-form>
          <el-form-item>
            <span style="position: relative; bottom: 0; right: 0; color:blue">
              已选商品 <span style="color: red">{{ dataList.length }}</span> 件
            </span>
            <span style="position: relative; bottom: 0; right: 0; color:blue">
              合计（不含运费）： <span style="color: red">{{ totalPrice }}</span> 元
            </span>
            <el-button
              size="mini"
              type="danger"
              @click="submitOrder"
            >
              提交订单
            </el-button>
          </el-form-item>
        </el-form>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import {getProduct, getUserDelivery, submitOrder} from '@/api/mall'

export default {
  name: 'ConfirmOrder',
  data() {
    return {
      addresses: [],
      radio: null,
      dataList: [],
      totalPrice: 0.00,
      productId: null,
      formData: {
        deliveryId: null
      }
    }
  },
  created() {
    document.title = '确认订单'
    this.productId = this.$route.query.productId
    var items = window.sessionStorage.getItem('cart0')
    if (items !== null) {
      this.dataList = JSON.parse(items)
      for (const item of this.dataList) {
        this.totalPrice += item.price * item.num
      }
    } else {
      getProduct(this.productId).then(resp => {
        if (resp.code === 0) {
          const item = resp.data
          item.num = 1
          this.dataList.push(item)
          this.totalPrice = item.price
        }
      })
    }

    getUserDelivery().then(resp => {
      if (resp.code === 0) {
        // console.log(resp.data)
        this.addresses = resp.data
      }
    })
  },
  methods: {
    submitOrder() {
      const jsonData = {}
      jsonData.deliveryId = 110011
      jsonData.items = []
      for (const item of this.dataList) {
        jsonData.items.push({ itemId: item.itemId, num: item.num, shopId: item.shopId, sellerId: item.sellerId })
      }

      submitOrder(jsonData).then(resp => {
        if (resp.code === 0) {
          const orderId = resp.data
          const loading = this.$loading({
            lock: true,
            text: '订单已提交, 跳转到支付页面',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          setTimeout(() => {
            loading.close()
            this.$router.push({
              path: '/mall/pay',
              query: {
                orderId: orderId
              }
            })
          }, 1000)
        }
      })
    }
  }
}
</script>

<style scoped>
/*处于手机屏幕时*/
@media screen and (max-width: 768px) {
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
}

.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}
</style>
